import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import XIcon from "../statics/x-black.png";
import { signInWith } from "../api/account";
import { useNotificationContext } from "../providers/NotificationProvider";

type Props = {
  type?: "login" | "signup";
};

const XSignUp = ({ type }: Props) => {
  const { showNotification } = useNotificationContext();

  return (
    <StyledContainer
      onClick={async () => {
        const result = await signInWith("x");
        if (result?.isNewUser) {
          showNotification("アカウントを作成しました");
        } else {
          showNotification("ログインしました");
        };
      }}
    >
      <img src={XIcon} width={18} alt="Xアイコン" />
      <Box>Xで{type === "login" ? "ログイン" : "登録"}</Box>
    </StyledContainer>
  )
};

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: 4,
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  fontWeight: 700,
  cursor: "pointer",
  border: "1px solid #f0f0f0",
}));

export default XSignUp;
