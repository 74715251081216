import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { ProductBrand } from "../../../types/database/productBrand";
import { createProductBrand, updateProductBrand } from "../../../api/productBrand";

type BrandFormValues = Pick<ProductBrand, "label" | "url" | "value">;

type Props = {
  brands: ProductBrand[];
  defaultValues: ProductBrand | null;
  open: boolean;
  onClose: () => void;
  reload: () => void;
};

const DefaultFormValues: BrandFormValues = {
  label: "",
  url: "",
  value: "",
};

const BrandFormDialog = ({
  brands,
  defaultValues,
  open,
  onClose,
  reload,
}: Props) => {
  const [formValues, setFormValues] = useState<BrandFormValues>(DefaultFormValues);
  const isEdit = Boolean(defaultValues);

  useEffect(() => {
    if (!defaultValues) return;
    setFormValues({
      label: defaultValues.label ?? "",
      url: defaultValues.url ?? "",
      value: defaultValues.value ?? "",
    });
  }, [defaultValues]);

  const handleClickSubmit = async () => {
    // URLチェック
    const isUrl = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/g.test(formValues.url);
    if (!isUrl) {
      alert("URLの形式が正しくありません");
      return;
    };

    // 重複チェック
    const otherBrands = brands.filter((brand) => brand.id !== defaultValues?.id);
    const isDuplicate = otherBrands.some((brand) => brand.value === formValues.value);
    if (isDuplicate) {
      alert("valueが他のブランドと重複しています");
      return;
    };

    // 英数字チェック
    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(formValues.value);
    if (!isAlphanumeric) {
      alert("valueは半角英数字で入力してください");
      return;
    };

    if (!!defaultValues) {
      await updateProductBrand(defaultValues.id, formValues);
    } else {
      await createProductBrand(formValues);
    };

    reload();
    onClose();
    setFormValues(DefaultFormValues);
  };

  const handleClose = () => {
    onClose();
    setFormValues(DefaultFormValues);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>ブランドの{isEdit ? "編集" : "追加"}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box>タイトル</Box>
          <Input
            fullWidth
            value={formValues.label}
            onChange={({ target }) => setFormValues({ ...formValues, label: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>URL</Box>
          <Input
            fullWidth
            value={formValues.url}
            onChange={({ target }) => setFormValues({ ...formValues, url: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>value</Box>
          <Input
            fullWidth
            value={formValues.value}
            onChange={({ target }) => setFormValues({ ...formValues, value: target.value })}
          />
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClickSubmit}>{isEdit ? "変更" : "作成"}</Button>
      </DialogActions>
    </Dialog>
  )
};

export default BrandFormDialog;
