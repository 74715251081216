export const UseCaseLabels = {
  insert: "挿入することが多い",
  inserted: "挿入されることが多い",
  both: "挿入・被挿入両方"
} as const;

export const RateLabels = {
  smell: "におい",
  thickness: "厚さ",
  touch: "なめらかさ",
  design: "デザイン",
  evaluation: "総合評価",
} as const;