import { useEffect, useState, Fragment } from "react";
import { Navigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@mui/material/styles/styled"
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { HOME_ROUTE, SETUP_ROUTE } from "../../routes";
import { User } from "../../types/frontend/user";
import { Review } from "../../types/frontend/review";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotificationContext } from "../../providers/NotificationProvider";
import { getUser } from "../../api/user";
import { getReviewsByUser, deleteReview } from "../../api/review";
import { RateLabels } from "../../lib/const";
import { UserProfile } from "./parts/UserProfile";
import ImageLoading from "../../components/ImageLoading";

export const Mypage = () => {
  const { showNotification } = useNotificationContext();
  const { currentUser, setupStatus, loading, isLogin } = useLoginContext();
  const userId = currentUser?.uid;
  const [tabValue, setTabValue] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!userId) return;

    Promise.all([getReviewsByUser(userId), getUser(userId)])
      .then(([reviews, userProfile]) => {
        setReviews(reviews);
        setUser(userProfile);
      });
  },[userId]);

  const handleDelete = async (review: Review) => {
    if (!userId) return;
    if (!window.confirm("本当に削除しますか？")) return;

    try {
      await deleteReview(review);

      // 削除後のレビュー一覧を取得するためにリロード
      const newReviews = await getReviewsByUser(userId);
      setReviews(newReviews);

      showNotification("レビューを削除しました");
    } catch (error) {
      console.error("レビューを削除に失敗しました");
    }
  };

  return loading ? (
    <ImageLoading />
  ) : !isLogin ? (
    <Navigate replace to={HOME_ROUTE}/>
  ) : setupStatus !== "done" ? (
    <StyledContainer>
      <Alert severity="warning"><Link href={SETUP_ROUTE}>こちら</Link>から必須項目を入力してください。</Alert>
    </StyledContainer>
  ) : (
    <StyledContainer>
      {/* 基本情報の表示 */}
      {user && <UserProfile user={user}/>}

      {/* タブのセクション */}
      <Box pt={5}>
        <Tabs value={tabValue} onChange={handleChange}>
          {/* <Tab label="お気に入り" /> */}
          <Tab label="レビュー" />
        </Tabs>

        {/* レビュー */}
        {tabValue === 0 && (
          <StyledReviewList>
            {reviews.map((review) => (
              <Fragment key={review.id}>
                <StyledReviewContainer>
                  <StyledImageContainer>
                    <StyledImage src={review.product?.imgUrl} alt={review.product?.title} />
                  </StyledImageContainer>
                  <StyledContentContainer>
                    <StyledTitleContainer>
                      <StyledLink href={`/catalog/${review.product?.id}`} target="_blank" rel="noopener noreferrer">
                        {review.product?.title}
                      </StyledLink>

                      <IconButton onClick={() => handleDelete(review)}>
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </StyledTitleContainer>
                    <StyledRatingContainer>
                      {review.rates.map((rate) => (
                        <Box key={rate.id} display="flex" alignItems="center">
                          <StyledCategoryLabel>{RateLabels[rate.category]}</StyledCategoryLabel>
                          <Rating value={rate.value} size="small" readOnly precision={0.1} />
                        </Box>
                      ))}
                    </StyledRatingContainer>
                    <Box>{review.comment}</Box>
                  </StyledContentContainer>
                </StyledReviewContainer>

                <Divider />
              </Fragment>
            ))}
          </StyledReviewList>
        )}
        {/* {tabValue === 0 && <Favorites />}
        {tabValue === 1 && <ReviewHistory />} */}
        {/* 診断への導線をおきたい */}
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 8),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}));

const StyledReviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const StyledRatingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}));

const StyledImageContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  }
}));

const StyledImage = styled("img")({
  width: 50,
  backgroundSize: "contain"
});

const StyledContentContainer = styled(Box)({
  flex: 1,
});

const StyledTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

const StyledLink = styled(Link)({
  fontSize: 18,
});

const StyledReviewList = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  display: "grid",
  gap: theme.spacing(4),
}));

const StyledCategoryLabel = styled(Box)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(12),
  },
}));