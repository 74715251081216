import { createData, getFilteredData, deleteData, deleteMultipleData } from "./firebaseApi";
import { Review as ReviewOnFront } from "../types/frontend/review";
import { Review } from "../types/database/review";
import { Rate } from "../types/database/rate";
import { Product } from "../types/database/product";
import { UserProfile } from "../types/database/userProfile";
import { documentId } from "firebase/firestore";

type ReviewProp = {
  review: string;
  evaluationRate: number;
  smellRate: number;
  thicknessRate: number;
  touchRate: number;
  designRate: number;
};

export const createReview = async (review: ReviewProp, productId: string, userId: string) => {
  const newReview = await createData("Reviews", {
    comment: review.review,
    productId: productId,
    userProfileId: userId
  });

  const newEvaluationRate = createData("Rates", {
    reviewId: newReview.id,
    userProfileId: userId,
    category: "evaluation",
    value: review.evaluationRate
  });
  const newSmellRate = createData("Rates", {
    reviewId: newReview.id,
    userProfileId: userId,
    category: "smell",
    value: review.smellRate
  });
  const newThicknessRate = createData("Rates", {
    reviewId: newReview.id,
    userProfileId: userId,
    category: "thickness",
    value: review.thicknessRate
  });
  const newTouchRate = createData("Rates", {
    reviewId: newReview.id,
    userProfileId: userId,
    category: "touch",
    value: review.touchRate
  });
  const newDesignRate = createData("Rates", {
    reviewId: newReview.id,
    userProfileId: userId,
    category: "design",
    value: review.designRate
  });

  await Promise.all([newEvaluationRate, newSmellRate, newThicknessRate, newTouchRate, newDesignRate]);
};

// MEMO: 〇〇ごとのReviews取得は同じメソッドで引数で切り替えとか？
export const getReviewsByProduct = async (productId: string): Promise<ReviewOnFront[]> => {
  const fetchedReviews = await getFilteredData<Review>("Reviews", "productId", "==", productId);
  if (fetchedReviews.length === 0) return [];

  const reviewIds = fetchedReviews.map((review) => review.id);
  const fetchedRates = await getFilteredData<Rate>("Rates", "reviewId", "in", reviewIds);
  const userIds = fetchedReviews.map((review) => review.userProfileId);
  const fetchedUsers = await getFilteredData<UserProfile>("UserProfiles", documentId(), "in", userIds);

  const reviews = fetchedReviews.map((review) => {
    const rates = fetchedRates.filter((rate) => rate.reviewId === review.id);
    return {
      id: review.id,
      comment: review.comment,
      product: null,
      rates: rates,
      user: fetchedUsers.find((user) => user.id === review.userProfileId)!
    };
  });

  return reviews;
};

export const getReviewsByUser = async (userId: string): Promise<ReviewOnFront[]> => {
  const fetchedReviews = await getFilteredData<Review>("Reviews", "userProfileId", "==", userId);
  if (fetchedReviews.length === 0) return [];

  const reviewIds = fetchedReviews.map((review) => review.id);
  const fetchedRates = await getFilteredData<Rate>("Rates", "reviewId", "in", reviewIds);
  const productIds = fetchedReviews.map((review) => review.productId);
  const fetchedProducts = await getFilteredData<Product>("Products", documentId(), "in", productIds);

  const reviews = fetchedReviews.map((review) => {
    const rates = fetchedRates.filter((rate) => rate.reviewId === review.id);
    return {
      id: review.id,
      comment: review.comment,
      product: fetchedProducts.find((product) => product.id === review.productId)!,
      rates: rates,
    };
  });

  return reviews;
};

export const deleteReview = async (review: ReviewOnFront) => {
  await deleteData("Reviews", review.id);
  // 削除するReviewと同じreviewIdを持っているRatesを削除する
  const rateIds = review.rates.map((rate) => rate.id);
  await deleteMultipleData("Rates", rateIds);
};
