import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Article } from "../../../types/database/article";
import { useBooleanState } from "../../../lib/hooks";
import { getAllArticles, deleteArticle } from "../../../api/article";
import TagLabel from "../../../components/TagLabel";
import ArticleFormDialog from "./ArticleFormDialog";

const ArticleList = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const [selectedArticle, setSelectedArticle] = useState<any | null>(null);

  useEffect(() => {
    getArticles();
  }, []);

  const getArticles = async () => {
    const res = await getAllArticles()
    setArticles(res);
  };

  const handleClickCell = (article?: Article) => {
    setSelectedArticle(article ?? null);
    openDialog();
  };

  const handleDelete = async (articleId: string) => {
    if (!window.confirm("本当に削除しますか？")) return;
    await deleteArticle(articleId);
    getArticles();
  };

  return (
    <>
      <Box>
        <StyledButtonContainer>
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={() => handleClickCell()}
          >
            新規作成
          </Button>
        </StyledButtonContainer>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>タイトル</TableCell>
                <TableCell>サマリー</TableCell>
                <TableCell>画像</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>URL元</TableCell>
                <TableCell>タグ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((article) => (
                <TableRow key={article.id} onClick={() => handleClickCell(article)}>
                  <TableCell>{article.title}</TableCell>
                  <TableCell>{article.summary}</TableCell>
                  <TableCell>
                    {article.imageUrl && <StyledImage src={article.imageUrl} alt={article.title}/>}
                  </TableCell>
                  <TableCell>{article.url}</TableCell>
                  <TableCell>{article.urlFrom}</TableCell>
                  <TableCell>
                    <StyledTaglabelContainer>
                      {article.tags.map((tag) => (
                        <TagLabel>{tag}</TagLabel>
                      ))}
                    </StyledTaglabelContainer>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        handleDelete(article.id);
                        e.stopPropagation();
                      }}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ArticleFormDialog
        defaultValues={selectedArticle}
        open={isDialogOpen}
        reload={getArticles}
        onClose={closeDialog}
      />
    </>
  )
};

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledTaglabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(0.5),
}));

const StyledImage = styled("img")({
  maxWidth: "100%",
  maxHeight: 150,
  backgroundSize: "contain",
});

export default ArticleList;