import { ComponentPropsWithoutRef } from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";

type Props = ComponentPropsWithoutRef<typeof Box> & {
  children: React.ReactNode;
};

const ErrorMessageLabel = ({ children, ...otherProps }: Props) => {
  return (
    <StyledErrorMessageLabel {...otherProps}>
      {children}
    </StyledErrorMessageLabel>
  )
};

const StyledErrorMessageLabel = styled(Box)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
}));

export default ErrorMessageLabel;
