import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getAllProducts } from "../../../api/product";
import { Product } from "../../../types/frontend/product";
import TagLabel from "../../../components/TagLabel";
import ProductFormDialog from "./ProductFormDialog";
import { useBooleanState } from "../../../lib/hooks";
import { deleteProduct } from "../../../api/product";

const ProductList = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const res = await getAllProducts()
    setProducts(res);
  };

  const handleDelete = async (productId: string) => {
    if (!window.confirm("本当に削除しますか？")) return;
    await deleteProduct(productId);
    getProducts();
  };

  const handleClickCell = (product?: Product) => {
    setSelectedProduct(product ?? null);
    openDialog();
  };

  return (
    <>
      <Box>
        <StyledButtonContainer>
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={() => handleClickCell()}
          >
            新規作成
          </Button>
        </StyledButtonContainer>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>商品名</TableCell>
                <TableCell>エバンジェリストレビュー</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>URL元</TableCell>
                <TableCell>タグ</TableCell>
                <TableCell>ブランド</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id} onClick={() => handleClickCell(product)}>
                  <TableCell>
                    <StyledImage src={product.imgUrl} alt={product.title}/>
                  </TableCell>
                  <TableCell>{product.title}</TableCell>
                  <TableCell>{product.evangelistReview}</TableCell>
                  <TableCell>{product.url}</TableCell>
                  <TableCell>{product.urlFrom}</TableCell>
                  <TableCell>
                    <StyledTaglabelContainer>
                      {product.tags.map((tag, index) => (
                        <TagLabel key={index} style={{ whiteSpace: "nowrap" }}>{tag.label}</TagLabel>
                      ))}
                    </StyledTaglabelContainer>
                  </TableCell>
                  <TableCell>{product.brand?.label}</TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        handleDelete(product.id);
                        e.stopPropagation();
                      }}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ProductFormDialog
        defaultValues={selectedProduct}
        open={isDialogOpen}
        reload={getProducts}
        onClose={closeDialog}
      />
    </>
  )
};

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledImage = styled("img")({
  maxWidth: "100%",
  maxHeight: 250,
  backgroundSize: "contain",
});

const StyledTaglabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(0.5),
}));

export default ProductList;