import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNotificationContext } from "../../../providers/NotificationProvider";
import { useBooleanState } from "../../../lib/hooks";
import { ProductTag } from "../../../types/database/productTag";
import { getAllProductTags, deleteProductTag } from "../../../api/productTag";
import TagFormDialog from "./TagFormDialog";

const TagList = () => {
  const { showNotification } = useNotificationContext();
  const [tags, setTags] = useState<ProductTag[]>([]);
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const [selectedTag, setSelectedTag] = useState<ProductTag | null>(null);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = async () => {
    const res = await getAllProductTags()
    setTags(res);
  };

  const handleClickCell = (tag?: ProductTag) => {
    setSelectedTag(tag ?? null);
    openDialog();
  };

  const handleDelete = async (tagId: string) => {
    if (!window.confirm("タグが商品に設定される場合は商品からも削除されます。本当に削除しますか？")) return;
    await deleteProductTag(tagId);

    showNotification("タグを削除しました");
    getTags();
  };

  return (
    <>
      <Box>
        <StyledButtonContainer>
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={() => handleClickCell()}
          >
            新規作成
          </Button>
        </StyledButtonContainer>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>タイトル</TableCell>
                <TableCell>value</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <TableRow key={tag.id} onClick={() => handleClickCell(tag)}>
                  <TableCell>{tag.label}</TableCell>
                  <TableCell>{tag.value}</TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        handleDelete(tag.id);
                        e.stopPropagation();
                      }}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TagFormDialog
        tags={tags}
        defaultValues={selectedTag}
        open={isDialogOpen}
        onClose={closeDialog}
        reload={getTags}
      />
    </>
  )
};

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-end",
}));

export default TagList;