import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import InstagramIcon from "@mui/icons-material/Instagram";
import { ReactComponent as XIcon } from "../../../statics/x-logo.svg";

const XUrl = "https://twitter.com/PlayrieInc";
const InstagramUrl = "https://instagram.com/playrie_official?igshid=ams3dDYxN3pxM2M0&utm_source=qr";

export const ContactToSns: React.FC = () => {
  return (
    <StyledSectionContainer>
      <StyledIconContainer>
        <InstagramIcon fontSize="large"/>
        <XIcon style={{ width: 25, height: 25 }} />
      </StyledIconContainer>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        お問い合わせはInstagram または Xで！
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        ご質問やお問い合わせは、Imstagram または X のDMにてお気軽にどうぞ。
      </Typography>
      <StyledButtonContainer>
        <StyledButton variant="contained" href={InstagramUrl} target="_blank" rel="noopener noreferrer">
          <InstagramIcon fontSize="small" />
          <Box>インスタでDMを送る</Box>
        </StyledButton>
        <StyledButton variant="contained" href={XUrl} target="_blank" rel="noopener noreferrer">
        <XIcon style={{ width: 15, height: 15 }} />
          <Box>DMを送る</Box>
        </StyledButton>
      </StyledButtonContainer>
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#0f1419", // Xのカラー
  textAlign: "center",
  color: "white",
  boxSizing: "border-box",
  padding: theme.spacing(8),
  marginTop: theme.spacing(15),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
})) as typeof Button;