import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Article } from "../../../types/database/article";
import { createArticle, updateArticle } from "../../../api/article";

type ArticleFormValues = Omit<Article, "id">;

type Props = {
  defaultValues: Article | null;
  open: boolean;
  onClose: () => void;
  reload: () => void;
};

const DefaultFormValues: ArticleFormValues = {
  title: "",
  summary: "",
  imageUrl: "",
  url: "",
  urlFrom: "notion",
  tags: [],
};

const BrandFormDialog = ({
  defaultValues,
  open,
  onClose,
  reload,
}: Props) => {
  const [formValues, setFormValues] = useState<ArticleFormValues>(DefaultFormValues);
  const isEdit = Boolean(defaultValues);

  useEffect(() => {
    if (!defaultValues) return;
    setFormValues({
      title: defaultValues.title,
      summary: defaultValues.summary,
      imageUrl: defaultValues.imageUrl,
      url: defaultValues.url,
      urlFrom: defaultValues.urlFrom,
      tags: defaultValues.tags,
    });
  }, [defaultValues]);

  const handleClickSubmit = async () => {
    // URLチェック
    const isUrl = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/g.test(formValues.url);
    if (!isUrl) {
      alert("URLの形式が正しくありません");
      return;
    };

    // タグ空文字チェック
    // 空文字の場合は配列から削除する
    formValues.tags = formValues.tags.filter((tag) => tag !== "");

    if (!!defaultValues) {
      await updateArticle(defaultValues.id, formValues);
    } else {
      await createArticle(formValues);
    };

    reload();
    onClose();
    setFormValues(DefaultFormValues);
  };

  const handleClose = () => {
    onClose();
    setFormValues(DefaultFormValues);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>記事の{isEdit ? "編集" : "追加"}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box>タイトル</Box>
          <Input
            fullWidth
            value={formValues.title}
            onChange={({ target }) => setFormValues({ ...formValues, title: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>サマリー</Box>
          <Input
            fullWidth
            value={formValues.summary}
            onChange={({ target }) => setFormValues({ ...formValues, summary: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>画像URL</Box>
          <Input
            fullWidth
            value={formValues.imageUrl}
            onChange={({ target }) => setFormValues({ ...formValues, imageUrl: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>URL</Box>
          <Input
            fullWidth
            value={formValues.url}
            onChange={({ target }) => setFormValues({ ...formValues, url: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>URL元</Box>
          <Select
            variant="standard"
            style={{ minWidth: 200 }}
            value={formValues.urlFrom}
            onChange={({ target }) => setFormValues({ ...formValues, urlFrom: target.value as Article["urlFrom"] })}
          >
            {["notion", "note"].map((urlFrom) => (
              <MenuItem key={urlFrom} value={urlFrom}>{urlFrom}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box mb={3}>
          <Box>タグ</Box>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {formValues.tags.map((tag, index) => (
              <Input
                key={index}
                value={tag}
                onChange={({ target }) => {
                  setFormValues({ ...formValues, tags: formValues.tags.map((tag, i) => i === index ? target.value : tag) })
                }}
              />
            ))}

            <Button onClick={() => setFormValues({ ...formValues, tags: [...formValues.tags, ""] })}>追加する</Button>
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClickSubmit}>{isEdit ? "変更" : "作成"}</Button>
      </DialogActions>
    </Dialog>
  )
};

export default BrandFormDialog;
