import { ComponentPropsWithoutRef } from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";

const TagLabel = (props: ComponentPropsWithoutRef<"div">) => {
  return <StyledTagLabel {...props}>{props.children}</StyledTagLabel>;
};

const StyledTagLabel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: theme.spacing(0.5, 1),
  borderRadius: 4,
  fontSize: 13,
}));

export default TagLabel;