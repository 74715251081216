import { useForm, Controller } from "react-hook-form";
import styled from "@mui/material/styles/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { useNotificationContext } from "../../../providers/NotificationProvider";

type DefaultFormValues = {
  smellRate: number | null;
  thicknessRate: number | null;
  touchRate: number | null;
  designRate: number | null;
  evaluationRate: number | null;
  review: string;
};

type FormValues = {
  smellRate: number;
  thicknessRate: number;
  touchRate: number;
  designRate: number;
  evaluationRate: number;
  review: string;
};

type ReviewDialogProps = {
  oncloseReviewModal: () => void;
  open: boolean;
  createReview: (reviewObj: FormValues) => void;
};

const DefaultValues ={
  evaluationRate: null, // 総合評価
  smellRate: null, // におい
  thicknessRate: null, // 厚さ
  touchRate: null, // なめらかさ
  designRate: null, // デザイン
  review: "" // レビュー
};

const ReviewDialog = ({ oncloseReviewModal, open, createReview }: ReviewDialogProps) => {
  const { showNotification } = useNotificationContext();
  const {
    handleSubmit,
    formState,
    control,
    reset
  } = useForm<DefaultFormValues, any, FormValues>({
    defaultValues: DefaultValues
  });
  const { errors } = formState;

  const onSubmit = (data: FormValues) => {
    createReview(data);
    showNotification("レビューを行いました");
    reset(DefaultValues);
    oncloseReviewModal();
  };

  return (
    <Dialog onClose={oncloseReviewModal} open={open} fullWidth maxWidth="sm">
      <DialogTitle>この商品をレビュー</DialogTitle>
      <DialogContent>
        <StyledRatingFormContainer>
          <Box>におい</Box>
          <Controller
            name="smellRate"
            control={control}
            rules={{ required: "においの評価を選択してください" }}
            render={({ field: { onChange } }) => (
              <Rating size="large" precision={0.5} onChange={(e: any) => onChange(Number(e.target.value))} />
            )}
          />
          {errors.smellRate && <StyledErrorMessage>{errors.smellRate.message}</StyledErrorMessage>}
        </StyledRatingFormContainer>
        <StyledRatingFormContainer>
          <Box>厚さ</Box>
          <Controller
            name="thicknessRate"
            control={control}
            rules={{ required: "厚さの評価を選択してください" }}
            render={({ field: { onChange } }) => (
              <Rating size="large" precision={0.5} onChange={(e: any) => onChange(Number(e.target.value))} />
            )}
          />
          {errors.thicknessRate && <StyledErrorMessage>{errors.thicknessRate.message}</StyledErrorMessage>}
        </StyledRatingFormContainer>
        <StyledRatingFormContainer>
          <Box>なめらかさ</Box>
          <Controller
            name="touchRate"
            control={control}
            rules={{ required: "なめらかさの評価を選択してください" }}
            render={({ field: { onChange } }) => (
              <Rating size="large" precision={0.5} onChange={(e: any) => onChange(Number(e.target.value))} />
            )}
          />
          {errors.touchRate && <StyledErrorMessage>{errors.touchRate.message}</StyledErrorMessage>}
        </StyledRatingFormContainer>
        <StyledRatingFormContainer>
          <Box>デザイン</Box>
          <Controller
            name="designRate"
            control={control}
            rules={{ required: "デザインの評価を選択してください" }}
            render={({ field: { onChange } }) => (
              <Rating size="large" precision={0.5} onChange={(e: any) => onChange(Number(e.target.value))} />
            )}
          />
          {errors.designRate && <StyledErrorMessage>{errors.designRate.message}</StyledErrorMessage>}
        </StyledRatingFormContainer>
        <StyledRatingFormContainer>
          <Box>総合評価</Box>
          <Controller
            name="evaluationRate"
            control={control}
            rules={{ required: "総合評価を選択してください" }}
            render={({ field: { onChange } }) => (
              <Rating size="large" precision={0.5} onChange={(e: any) => onChange(Number(e.target.value))} />
            )}
          />
          {errors.evaluationRate && <StyledErrorMessage>{errors.evaluationRate.message}</StyledErrorMessage>}
        </StyledRatingFormContainer>
        <Box mt={2}>
          <Controller
            name="review"
            control={control}
            rules={{
              required: "レビューを入力してください",
              maxLength: { value: 800, message: "800文字以内で入力してください" }
            }}
            render={({ field }) => (
              <TextField {...field} fullWidth multiline rows={4} placeholder="この商品の感想をご記入ください。" />
            )}
          />
          {errors.review && <StyledErrorMessage>{errors.review.message}</StyledErrorMessage>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          レビューを作成
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const StyledRatingFormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

const StyledErrorMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: 12,
}));

export default ReviewDialog;
