import CircularProgress from "@mui/material/CircularProgress";

const ImageLoading = () => {
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "45%",
        left: "50%",
      }}
    >
      <CircularProgress />
    </div>
  );
};
export default ImageLoading;
