import { useCallback, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { UserProfile } from "../types/database/userProfile";
import { getSingleData } from "../api/firebaseApi";
import { createContext } from "./utils";

type LoginContextType = {
  loading: boolean;
  isLogin: boolean;
  currentUser: User | null,
  userProfile: UserProfile | null,
  setupStatus: "notDone" | "done" | null,
  reloadUserProfile: () => Promise<void>;
};

export const LoginContextProvider = (({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [setupStatus, setSetupStatus] = useState<any | null>(null);

  useEffect(() => {
    const unsubscribed = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        setIsLogin(true);
        setCurrentUser(user);

        const newUserProfile = await getSingleData<UserProfile>("UserProfiles", user.uid);
        setUserProfile(newUserProfile);

        const isSetupComplete = !!newUserProfile?.nickname || !!newUserProfile?.birthYear || !!newUserProfile?.usecase;
        const setupStatus = isSetupComplete ? "done" : "notDone";
        setSetupStatus(setupStatus);
      } else {
        setIsLogin(false);
        setCurrentUser(null);
        setUserProfile(null);
        setSetupStatus(null);
      }

      setLoading(false);
    });

    return () => unsubscribed();
  }, []);

  const reloadUserProfile = useCallback(async () => {
    if (!currentUser) return;
    const newUserProfile = await getSingleData<UserProfile>("UserProfiles", currentUser.uid);
    setUserProfile(newUserProfile);

    const isSetupComplete = !!newUserProfile?.nickname || !!newUserProfile?.birthYear || !!newUserProfile?.usecase;
    const setupStatus = isSetupComplete ? "done" : "notDone";
    setSetupStatus(setupStatus);
  }, [currentUser]);

  const value ={
    loading,
    isLogin,
    setupStatus,
    currentUser,
    userProfile,
    reloadUserProfile
  };

  return (
    <LoginContext.Provider value={value}>
      {children}
    </LoginContext.Provider>
  );
});

export const [useLoginContext, LoginContext] = createContext<LoginContextType>();