import { Box } from "@mui/material";
import React from "react";

interface SectionProps {
  children: React.ReactNode;
  customStyle?: React.CSSProperties; // カスタムスタイルを許可
}

export const SectionContainer: React.FC<SectionProps> = ({ children, customStyle }) => {
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...customStyle, // カスタムスタイルを適用
      }}
    >
      {children}
    </Box>
  );
};