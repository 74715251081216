export const HOME_ROUTE = "/"
export const SETUP_ROUTE = "/setup"
export const LOGIN_ROUTE = "/login"
export const SIGNUP_ROUTE = "/signup"
export const ADMIN_ROUTE = "/admin"
export const CHOICE_GUIDE_ROUTE = "/choiceGuide"
export const CHOICE_GUIDE_ARTICLE_ROUTE = "/choiceGuideAeticle"
export const CATALOG_ROUTE = "/catalog"
export const CATALOG_DETAIL_ROUTE = "/catalog/:id"
export const DIAGNOSIS_ROUTE = "/diagnosis"
export const MYPAGE_ROUTE = "/mypage"
export const PASSWORD_RESET_ROUTE = "/passwordReset"
export const POST_REVIEW = "/postReview"