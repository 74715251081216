import { Article } from "../types/database/article";
import { getAllData, createData, updateData, deleteData } from "./firebaseApi";

export const getAllArticles = async ():Promise<Article[]> => {
  const articles: Article[] = await getAllData("Articles");
  return articles;
};

type ArticleFormValues = Omit<Article, "id">;

export const createArticle = async (article: ArticleFormValues):Promise<void> => {
  await createData("Articles", article);
};

export const updateArticle = async (id: string, article: ArticleFormValues):Promise<void> => {
  await updateData("Articles", id, article);
};

export const deleteArticle = async (id: string):Promise<void> => {
  await deleteData("Articles", id);
};