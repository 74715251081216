import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { ProductTag } from "../../../types/database/productTag";
import { createProductTag, updateProductTag } from "../../../api/productTag";

type TagFormValues = Pick<ProductTag, "label" | "value">;

type Props = {
  tags: ProductTag[];
  defaultValues: ProductTag | null;
  open: boolean;
  onClose: () => void;
  reload: () => void;
};

const DefaultFormValues: TagFormValues = {
  label: "",
  value: "",
};

const TagFormDialog = ({
  tags,
  defaultValues,
  open,
  onClose,
  reload,
}: Props) => {
  const [formValues, setFormValues] = useState<TagFormValues>(DefaultFormValues);
  const isEdit = Boolean(defaultValues);

  useEffect(() => {
    if (!defaultValues) return;
    setFormValues({
      label: defaultValues.label ?? "",
      value: defaultValues.value ?? "",
    });
  }, [defaultValues]);

  const handleClickSubmit = async () => {
    // 重複チェック
    const otherTags = tags.filter((tag) => tag.id !== defaultValues?.id);
    const isDuplicate = otherTags.some((tag) => tag.value === formValues.value);
    if (isDuplicate) {
      alert("valueが他のタグと重複しています");
      return;
    };

    // 英数字チェック
    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(formValues.value);
    if (!isAlphanumeric) {
      alert("valueは半角英数字で入力してください");
      return;
    };

    if (!!defaultValues) {
      await updateProductTag(defaultValues.id, formValues);
    } else {
      await createProductTag(formValues);
    };

    reload();
    onClose();
    setFormValues(DefaultFormValues);
  };

  const handleClose = () => {
    onClose();
    setFormValues(DefaultFormValues);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>タグの{isEdit ? "編集" : "追加"}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box>タイトル</Box>
          <Input
            fullWidth
            value={formValues.label}
            onChange={({ target }) => setFormValues({ ...formValues, label: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>value</Box>
          <Input
            fullWidth
            value={formValues.value}
            onChange={({ target }) => setFormValues({ ...formValues, value: target.value })}
          />
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClickSubmit}>{isEdit ? "変更" : "作成"}</Button>
      </DialogActions>
    </Dialog>
  )
};

export default TagFormDialog;
