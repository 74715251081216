import { Link, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LOGIN_ROUTE, MYPAGE_ROUTE, SIGNUP_ROUTE } from "../routes";
import { useBooleanState } from "../lib/hooks";
import { useLoginContext } from "../providers/LoginContextProvider";
import { useNotificationContext } from "../providers/NotificationProvider";
import { logOut } from "../api/account";

const Header= (): JSX.Element => {
  const { isLogin, userProfile } = useLoginContext();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();
  const [isDrawerOpen, , , toggleDrawer] = useBooleanState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleLogout = async () => {
    await logOut();
    showNotification("ログアウトしました");
    navigate(LOGIN_ROUTE);
  };

  const menuItems = isLogin
    ? [ { link: MYPAGE_ROUTE, text: "マイページ" } ]
    : [ { link: LOGIN_ROUTE, text: "ログイン" }, { link: SIGNUP_ROUTE, text: "新規登録" } ];

  const drawer = (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button component={Link} to={item.link} key={index}>
            {item.text}
          </ListItem>
        ))}
        {isLogin && <ListItem onClick={handleLogout}>ログアウト</ListItem>}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static" sx={{ "& Button": { color: "inherit" } }}>
      <Toolbar>
        {isMobile ? (
          <>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                ぐらぶ
              </Link>
            </Typography>

            <IconButton edge="end" color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            {drawer}
          </>
        ) : (
          <>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                ぐらぶ
              </Link>
            </Typography>
            {menuItems.map((item, index) => (
              <Button color="inherit" component={Link} to={item.link} key={index}>
                {item.text}
              </Button>
            ))}
            {isLogin && <Button onClick={handleLogout}>ログアウト</Button>}
            {isLogin && userProfile?.isAdmin && (
              <Button color="inherit" component={Link} to="/admin">管理画面</Button>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
