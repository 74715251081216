import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNotificationContext } from "../../../providers/NotificationProvider";
import { useBooleanState } from "../../../lib/hooks";
import { getAllProductBrands, deleteProductBrand } from "../../../api/productBrand";
import { ProductBrand } from "../../../types/database/productBrand";
import BrandFormDialog from "./BrandFormDialog";

const BrandList = () => {
  const { showNotification } = useNotificationContext();
  const [brands, setBrands] = useState<ProductBrand[]>([]);
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const [selectedBrand, setSelectedBrand] = useState<ProductBrand | null>(null);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    const res = await getAllProductBrands()
    setBrands(res);
  };

  const handleClickCell = (brand?: ProductBrand) => {
    setSelectedBrand(brand ?? null);
    openDialog();
  };

  const handleDelete = async (brandId: string) => {
    if (!window.confirm("ブランドが商品に設定される場合は商品からも削除されます。本当に削除しますか？")) return;
    await deleteProductBrand(brandId);

    showNotification("ブランドを削除しました");
    getBrands();
  };

  return (
    <>
      <Box>
        <StyledButtonContainer>
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={() => handleClickCell()}
          >
            新規作成
          </Button>
        </StyledButtonContainer>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>タイトル</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>value</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brands.map((brand) => (
                <TableRow key={brand.id} onClick={() => handleClickCell(brand)}>
                  <TableCell>{brand.label}</TableCell>
                  <TableCell>{brand.url}</TableCell>
                  <TableCell>{brand.value}</TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        handleDelete(brand.id);
                        e.stopPropagation();
                      }}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <BrandFormDialog
        brands={brands}
        defaultValues={selectedBrand}
        open={isDialogOpen}
        reload={getBrands}
        onClose={closeDialog}
      />
    </>
  )
};

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "flex-end",
}));

export default BrandList;