import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { checkUser, passwordReset } from "../../../api/account";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HOME_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from "../../../routes";
import { useTheme } from "@mui/material";

export default function PasswordResetForm() {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("email") == null) {
      setErrorMessage("メールアドレスを入力してください。");
      return;
    }
    setErrorMessage(null);
    setSuccessMessage(null);

    const result = await passwordReset(
      String(data.get("email"))
    );
    if (result === "invaild") {
      setErrorMessage("不正なメールアドレスです。");
      return;
    } else if (result === "success") {
      setSuccessMessage("入力されたメールアドレス宛にパスワードのリセットのメールを送信しました。パスワードのリセット後、ログイン画面よりログインしてください。");
    } else if (result === "notFound") {
      setErrorMessage("登録されていないメールアドレスです。");
    } else {
      setErrorMessage("予期せぬエラーが発生しました。");
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    checkUser().then((result) => result && navigate(HOME_ROUTE));
  }, [navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワードのリセット
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {errorMessage && (
            <Typography component="h3" variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography component="h3" variant="body2">
              {successMessage}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            パスワードのリセット
          </Button>

          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Link
                to={LOGIN_ROUTE}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                }}
              >
                ログイン
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={SIGNUP_ROUTE}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                }}
              >
                新規登録
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
