import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { createContext } from "./utils";
import { useBooleanState } from "../lib/hooks";

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, openNotification, closeNotification] = useBooleanState(false);
  const [message, setMessage] = useState<string>();

  const showNotification = (message: string) => {
    openNotification();
    setMessage(message);
  };

  const value = {
    showNotification,
  }

  return (
    <NotificationContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={open}
        onClose={closeNotification}
        autoHideDuration={3000}
        message={message}
      />
      {children}
    </NotificationContext.Provider>
  );
};

type NotificationContextType = {
  showNotification: (message: string) => void;
};

export const [useNotificationContext, NotificationContext] = createContext<NotificationContextType>();
