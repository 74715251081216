import {
  getAllData,
  getSingleData,
  getFilteredData,
  createData,
  updateData,
  deleteData,
} from "./firebaseApi";
import { Product as ProductOnFront } from "../types/frontend/product";
import { Review } from "../types/database/review"
import { Rate } from "../types/database/rate"
import { Product } from "../types/database/product";
import { ProductTag } from "../types/database/productTag";
import { ProductBrand } from "../types/database/productBrand";
import { documentId } from "firebase/firestore";

export const getAllProducts = async (): Promise<ProductOnFront[]> => {
  const productsOnDb: Product[] = await getAllData<Product>("Products");
  const productTags: ProductTag[] = await getAllData<ProductTag>("ProductTags");
  const productBrands: ProductBrand[] = await getAllData<ProductBrand>("ProductBrands");
  const reviews: Review[] = await getAllData<Review>("Reviews");
  const rates: Rate[] = await getFilteredData<Rate>("Rates", "category", "==", "evaluation");

  const productsPromises: Promise<ProductOnFront>[] = productsOnDb.map(async (product) => {
    const reviewsByProduct = reviews.filter(review => review.productId === product.id);
    const rateByProduct = rates.filter(rate => reviewsByProduct.some(review => review.id === rate.reviewId));

    return {
      id: product.id,
      title: product.title,
      url: product.url,
      urlFrom: product.urlFrom,
      productCategory: product.productCategory,
      imgUrl: product.imgUrl,
      tags: productTags.filter(tag => product.tagIds.includes(tag.id)),
      brand: productBrands.filter(brand => product.brandId === brand.id)[0],
      reviews: reviewsByProduct,
      evangelistReview: product.evangelistReview,
      evaluationAverageRate: rateByProduct.length === 0 ? null : rateByProduct.reduce((sum, value) => sum + value.value, 0) / rateByProduct.length,
    };
  });

  const products = await Promise.all(productsPromises);
  return products;
};

export const getProduct = async (id: string): Promise<any | null> => {
  const product = await getSingleData<Product>("Products", id);
  if (!product) return null;

  const productTags = product.tagIds.length >= 1
    ? await getFilteredData<ProductTag>("ProductTags", documentId(), "in", product.tagIds)
    : [];

  const productBrand = product.brandId
    ? await getSingleData<ProductBrand>("ProductBrands", product.brandId)
    : null;

  return {
    id: product.id,
    title: product.title,
    url: product.url,
    urlFrom: product.urlFrom,
    productCategory: product.productCategory,
    imgUrl: product.imgUrl,
    evangelistReview: product.evangelistReview,
    tags: productTags.filter(tag => product.tagIds.includes(tag.id)),
    brand: productBrand,
  };
};

type ProductFormValues = Pick<
  Product,
  "title" | "evangelistReview" | "url" | "urlFrom" | "imgUrl" | "tagIds" | "brandId"
>;

export const createProduct = async (product: ProductFormValues): Promise<void> => {
  await createData("Products", product);
};

export const updateProduct = async (id: string, product: ProductFormValues): Promise<void> => {
  await updateData("Products", id, product);
};

export const deleteProduct = async (id: string): Promise<void> => {
  await deleteData("Products", id);
};