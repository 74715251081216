import { Box, Button, useTheme } from "@mui/material";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export const LinkButton = ({
  to,
  label,
  icon,
  isScroll = false,
}: {
  to: string;
  label: string;
  icon: ReactElement;
  isScroll?: boolean;
}) => {
  return isScroll ? (
    <a href={to} style={{ textDecoration: "none" }}>
      <ButtonContents label={label} icon={icon} />
    </a>
  ) : (
    <Link to={to} style={{ textDecoration: "none" }}>
      <ButtonContents label={label} icon={icon} />
    </Link>
  );
};

const ButtonContents = ({
  label,
  icon,
}: {
  label: string;
  icon: ReactElement;
}) => {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      sx={{
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        minWidth: "220px",
        height: "60px",
        display: "flex",
        alignItems: "center", // アイテムを中央に配置
        justifyContent: "center",
        fontSize: "18px",
      }}
    >
      {icon && (
        <Box
          sx={{ marginRight: "10px", display: "flex", alignItems: "center" }}
        >
          {icon}
        </Box>
      )}
      {label}
    </Button>
  );
};
