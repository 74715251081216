import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

export const DiagnosisIntroduction: React.FC = () => {
  return (
    <StyledSectionContainer>
      <StyledCatchphraseContainer>
        <StyledCatchphrase>最適コンドーム診断機能、間もなく登場！</StyledCatchphrase>
      </StyledCatchphraseContainer>

      <Typography variant="subtitle1" sx={{ marginBottom: 4, textAlign: "center" }}>
        シンプルな質問に答えるだけで、あなたに最適なコンドームを推薦します。お楽しみに！
      </Typography>

      <Button variant="outlined" disabled>Coming Soon</Button>
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  marginTop: theme.spacing(15),

  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledCatchphraseContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledCatchphrase = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 48,

  [theme.breakpoints.down("sm")]: {
    fontSize: 36,
  },
}));