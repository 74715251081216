import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  QueryDocumentSnapshot,
  WhereFilterOp,
  FieldPath,
  writeBatch
} from "firebase/firestore";
import { CollectionName } from "../types/database/collectionName";

// Firestoreのインスタンスを作成
const db = getFirestore();

// 全件取得関数
export async function getAllData<T>(collectionName: CollectionName): Promise<T[]> {
  const q = collection(db, collectionName);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(
    (doc: QueryDocumentSnapshot) =>
      Object.assign(doc.data(), { id: doc.id }) as T
  );
};

// 一件取得関数
// DB内に登録してあるidではなくドキュメントのIDを指定する
export async function getSingleData<T>(
  collectionName: CollectionName,
  docId: string
): Promise<T | null> {
  const docRef = doc(db, collectionName, docId);
  const docSnap = await getDoc(docRef);

  return docSnap.exists()
    ? (Object.assign(docSnap.data(), { id: docSnap.id }) as T)
    : null;
};

// 条件絞り込みで取得関数
export async function getFilteredData<T>(
  collectionName: CollectionName,
  field: string | FieldPath,
  operator: WhereFilterOp,
  value: any
): Promise<T[]> {
  const q = query(
    collection(db, collectionName),
    where(field, operator, value)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(
    (doc: QueryDocumentSnapshot) =>
      Object.assign(doc.data(), { id: doc.id }) as T
  );
};

export async function createData(
  collectionName: CollectionName,
  data: { [key: string]: any }
) {
  const res = await addDoc(collection(db, collectionName), data);
  return res;
};

export async function updateData(
  collectionName: CollectionName,
  docId: string,
  data: { [key: string]: any }
) {
  const docRef = doc(db, collectionName, docId);
  const res = await updateDoc(docRef, data);
  return res;
};

export async function deleteData(
  collectionName: CollectionName,
  docId: string,
) {
  const res = await deleteDoc(doc(db, collectionName, docId));
  return res;
};

export async function deleteMultipleData(
  collectionName: CollectionName,
  docIds: string[],
) {
  const batch = writeBatch(db);
  docIds.forEach((docId) => {
    const ref = doc(db, collectionName, docId);
    batch.delete(ref);
  })

  await batch.commit();
};
