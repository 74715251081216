import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
  getRedirectResult,
  sendPasswordResetEmail,
  getAdditionalUserInfo
} from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";

const auth = getAuth();
const db = getFirestore();
// const email = "user@example.com";
// const password = "secretPassword";

export const passwordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return "success"
  } catch (err){
    const error = err as FirebaseError;
    switch (error.code) {
      case 'auth/invalid-email':
        // メールアドレスが無効な形式の場合の処理
        return "invaild"
      case 'auth/user-not-found':
        // メールアドレスに対応するユーザーが存在しない場合の処理
        return "notFound"
      case 'auth/too-many-requests':
        // リクエストが多すぎる場合の処理
        return "tooManyRequests"
      // その他のエラー処理
    }
  }
}

export const signUpWithEmail = async (
  email: string,
  password: string,
  nickname: string,
  birthYear: number,
  usecase: "insert" | "inserted" | "both"
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // ユーザー登録成功時の処理
    const user = userCredential.user;
    await setDoc(doc(db, "UserProfiles", user.uid), {
      nickname,
      birthYear,
      usecase,
      profileComment: "",
      isAdmin: false,
    });
    // 成功した場合は "success" を返す
    return "success";
  } catch (err) {
    console.error(err);
    const error = err as FirebaseError;
    switch (error.code) {
      // すでにアドレスが使用されている場合
      case "auth/email-already-in-use":
        return "alreadyInUse";
      // メールアドレスが不正な場合
      case "auth/invalid-email":
        return "illegalEmail";
      // それ以外のエラー
      default:
        return "error";
    }
  }
};

export const logOut = async () => {
  try {
    await signOut(auth);
    return "success"
  } catch (error) {
    console.error(error);
    return "failed"
  }
}

export const signInWithEmail = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return "success"
  } catch(err) {
      // // ログイン失敗時のエラーハンドリング
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // ...
      
      console.error(err);
      return "failed"
    };
};

export const signInWith = async (type: "google" | "x") => {
  try {
    const provider = type === "google"
      ? new GoogleAuthProvider()
      : new TwitterAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

    if (isNewUser) {
      const uid = result.user.uid;
      // TODO: docIdしての場合もfirebaseApi.tsで対応できるようにしたい
      await setDoc(doc(db, "UserProfiles", uid), {
        nickname: "",
        birthYear: null,
        usecase: "",
        profileComment: "",
        isAdmin: false,
      });
    }

    return { ...result, isNewUser };
  } catch (err) {
    console.error(err);
    return;
  }
};

export const checkUser = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result && result.user) {
      const uid = result.user.uid;
      const userDoc = doc(db, "UserProfiles", uid);
      const userSnap = await getDoc(userDoc);

      // UserProfileが存在しなければ新たに作成
      if (!userSnap.exists()) {
        setDoc(doc(db, "UserProfiles", uid), {});
      }
      // ユーザーチェックが成功した場合に true を返す
      return true;
    } else {
      // ユーザーチェックが失敗した場合に false を返す
      return false;
    }
  } catch (err) {
    console.error(err);
    // エラーが発生した場合に false を返す
    return false;
  }
};