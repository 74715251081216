import React from "react";
import { SectionContainer } from "./SectionContainer";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import CatalogIcon from "@mui/icons-material/MenuBook"; // カタログアイコン
import { ReactComponent as Logo } from "../../../statics/logo.svg";
import { LinkButton } from "../../../components/LinkButton";
import bgImage from '../../../statics/mainVisualImage.png'; // 画像のパスに応じて変更してください
import { LightbulbOutlined } from "@mui/icons-material";
import { CATALOG_ROUTE, CHOICE_GUIDE_ROUTE } from "../../../routes";

export const MainVisual: React.FC = () => {
  const theme = useTheme();
  return (
    <SectionContainer>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: `linear-gradient(${theme.palette.secondary.main}AA, ${theme.palette.secondary.main}AA), url(${bgImage}) no-repeat center center`,  // 画像の背景を設定
          backgroundSize: 'cover', // 画像がコンテナを覆うように設定
          backgroundColor: theme.palette.secondary.main, // 背景色も保持
        }}
      >
        <Logo />
        <StyledCatchphrase>
          新しいコンドーム体験、始めよう！あなたの選ぶ楽しみ、ここから。
        </StyledCatchphrase>
        <StyledLinkContainer>
          <LinkButton
            to={CATALOG_ROUTE}
            label={"コンドームカタログ"}
            icon={<CatalogIcon />}
          />
          {/* <LinkButton
            to={"#diagnosis"}
            label={"最適コンドーム診断"}
            icon={<DiagnosisIcon />}
          /> */}
          <LinkButton
            to={CHOICE_GUIDE_ROUTE}
            label={"コンドームの選び方"}
            icon={<LightbulbOutlined />}
          />
        </StyledLinkContainer>
      </Box>
    </SectionContainer>
  );
};

const StyledCatchphrase = styled(Box)(({ theme }) => ({
  fontSize: 34,
  fontWeight: "bold",
  marginBottom: theme.spacing(5),
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

const StyledLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  paddingBottom: theme.spacing(15),
}));