import { ProductTag } from "../types/database/productTag";
import { Product } from "../types/database/product";
import { getAllData, getFilteredData, createData, updateData, deleteData } from "./firebaseApi";

export const getAllProductTags = async (): Promise<ProductTag[]> => {
  const productTags: ProductTag[] =  await getAllData<ProductTag>("ProductTags");

  return productTags;
};

type TagFormValues = Pick<ProductTag, "label" | "value">;

export const createProductTag = async (productTag: TagFormValues): Promise<void> => {
  await createData("ProductTags", productTag);
};

export const updateProductTag = async (id: string, productTag: TagFormValues): Promise<void> => {
  await updateData("ProductTags", id, productTag);
};

export const deleteProductTag = async (tagId: string) => {
  await deleteData("ProductTags", tagId);

  const products = await getFilteredData<Product>("Products", "tagIds", "array-contains", tagId);
  products.forEach((product) => {
    const newTagIds = product.tagIds.filter(productTagId => productTagId !== tagId);
    updateData("Products", product.id, { tagIds: newTagIds });
  });
}