import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { signInWithEmail } from "../../../api/account";
import { HOME_ROUTE, PASSWORD_RESET_ROUTE, SIGNUP_ROUTE } from "../../../routes";
import { useNotificationContext } from "../../../providers/NotificationProvider";

const EmailLoginForm = () => {
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("email") == null || data.get("password") == null) {
      setErrorMessage("メールアドレスとパスワードを入力してください。");
      return;
    }
    setErrorMessage(null);

    const result = await signInWithEmail(
      String(data.get("email")),
      String(data.get("password"))
    );
    if (result === "failed") {
      setErrorMessage("メールアドレスかパスワードが間違っています。");
      return;
    } else if (result === "success") {
      showNotification("ログインしました");
      navigate(HOME_ROUTE);
    } else {
      setErrorMessage("予期せぬエラーが発生しました。");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="メールアドレス"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="パスワード"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      {errorMessage && (
        <Typography component="h3" variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        ログイン
      </Button>

      <Box display="flex" justifyContent="space-between">
        <StyledLink to={PASSWORD_RESET_ROUTE}>パスワードをお忘れの方</StyledLink>
        <StyledLink to={SIGNUP_ROUTE}>新規登録</StyledLink>
      </Box>
    </Box>
  )
};

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

export default EmailLoginForm;
