import { Typography, Paper, Grid } from '@mui/material';
import { User } from '../../../types/frontend/user';
import { UseCaseLabels } from "../../../lib/const";

export function UserProfile({ user }:{user:User}) {
  return (
    <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px' }}>
      <Typography variant="h5" gutterBottom>プロフィール</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>ニックネーム</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{user.nickname}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1"><strong>誕生年</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{user.birthYear}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1"><strong>紹介文</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{user.profileComment}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1"><strong>利用シーン</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{UseCaseLabels[user.usecase]}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}