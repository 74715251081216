import { useState } from "react";

type UseBooleanStateReturnType = [boolean, () => void, () => void, () => void];
export function useBooleanState (defaultValue: boolean): UseBooleanStateReturnType {
  const [value, setValue] = useState(defaultValue);

  return [
    value,
    () => setValue(true),
    () => setValue(false),
    () => setValue((prev) => !prev),
  ]
}