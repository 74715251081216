import { ProductBrand } from "../types/database/productBrand";
import { Product } from "../types/database/product";
import { getAllData, getFilteredData, createData, updateData, deleteData } from "./firebaseApi";

export const getAllProductBrands = async (): Promise<ProductBrand[]> => {
  const productBrands: ProductBrand[] =  await getAllData<ProductBrand>("ProductBrands");

  return productBrands;
};

type BrandFormValues = Pick<ProductBrand, "label" | "url" | "value">;

export const createProductBrand = async (productBrand: BrandFormValues): Promise<void> => {
  await createData("ProductBrands", productBrand);
};

export const updateProductBrand = async (id: string, productBrand: BrandFormValues): Promise<void> => {
  await updateData("ProductBrands", id, productBrand);
};

export const deleteProductBrand = async (brandId: string) => {
  await deleteData("ProductBrands", brandId);

  const products = await getFilteredData<Product>("Products", "brandId", "==", brandId);
  products.forEach((product) => {
    updateData("Products", product.id, { brandId: "" });
  });
};
