import { getSingleData, getFilteredData, updateData } from "./firebaseApi";
import { User } from "../types/frontend/user";
import { Review as ReviewOnUser } from "../types/frontend/review"
import { Rate } from "../types/database/rate";
import { Review } from "../types/database/review";
import { Product } from "../types/database/product";
import { UserProfile } from "../types/database/userProfile";

export const getUser = async (userDocId: string): Promise<User | null> => {
  const userProfile: UserProfile | null = await getSingleData<UserProfile>(
    "UserProfiles",
    userDocId
  );

  if(userProfile === null) return null;

  const reviews: Review[] = await getFilteredData<Review>(
    "Reviews",
    "userProfileId",
    "==",
    userProfile.id
  );

  const userReviews: ReviewOnUser[] = await Promise.all(
    reviews.map(async (r) => {
      const product = await getSingleData<Product>("Products", `${r.productId}`);
      const rates = await getFilteredData<Rate>(
        "Rates",
        "reviewId",
        "==",
        r.id
      );
      return {
        id: r.id,
        comment: r.comment,
        product: product,
        rates: rates,
      };
    })
  );

  return {
    id: userProfile.id,
    nickname: userProfile.nickname,
    birthYear: userProfile.birthYear,
    iconUrl: userProfile.iconUrl,
    usecase: userProfile.usecase,
    preference: userProfile.preference
      ? JSON.parse(userProfile.preference)
      : "",
    reviews: userReviews,
    profileComment: userProfile.profileComment
  };
};

type UserFormValues = Pick<
  UserProfile,
  "nickname" | "birthYear" | "usecase"
>;

export const updateUser = async (docId: string, user: UserFormValues) => {
  await updateData("UserProfiles", docId, user);
};