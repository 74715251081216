import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import { Rate } from "../../types/database/rate";
import { ProductDetail } from "../../types/frontend/product";
import { Review } from "../../types/frontend/review";
import { SETUP_ROUTE } from "../../routes";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotificationContext } from "../../providers/NotificationProvider";
import { useBooleanState } from "../../lib/hooks";
import { UseCaseLabels } from "../../lib/const";
import { RateLabels } from "../../lib/const";
import { getProduct } from "../../api/product";
import { createReview, getReviewsByProduct, deleteReview } from "../../api/review";
import ImageLoading from "../../components/ImageLoading";
import TagLabel from "../../components/TagLabel";
import ReviewModal from "./parts/ReviewModal";

type RateCategory = "smell" | "thickness" | "touch" | "design" | "evaluation";
const CategoryOrder = ["smell", "thickness", "touch", "design", "evaluation"];

type RateType = {
  smellRate: number;
  thicknessRate: number;
  touchRate: number;
  designRate: number;
  evaluationRate: number;
};

type ReviewType = RateType & {
  review: string;
}

export const CatalogDetailView = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();
  const { isLogin, currentUser, setupStatus } = useLoginContext();
  const { id } = useParams();
  const [isOpenReviewModal, openReviewModal, closeReviewModal] = useBooleanState(false);
  const [isOpenSetupnModal, openSetupModal, closeSetupModal] = useBooleanState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [product, setProduct] = useState<ProductDetail | null>();

  const openProductPage = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    if (!id) return;

    // プロダクトの情報
    getProduct(id).then((product) => {
      setProduct(product);
    });
    // レビューの情報
    fetchReviewsByProduct(id);
  }, [id]);

  const handleDelete = async (review: Review) => {
    if (!id) return;
    if (!window.confirm("本当に削除しますか？")) return;

    try {
      await deleteReview(review);

      // 削除後のレビュー一覧を取得するためにリロード
      await fetchReviewsByProduct(id);

      showNotification("レビューを削除しました");
    } catch (error) {
      console.error("レビューを削除に失敗しました");
    }
  };

  const fetchReviewsByProduct = async (productId: string) => {
    const newReviews = await getReviewsByProduct(productId);
    setReviews(newReviews);
  };

  const filterRatesByCategory = (category: RateCategory): Rate[] => {
    const result = reviews.map((review) => (
      review.rates.find((rate) => rate.category === category)
    )).filter((v): v is Rate => v !== undefined);

    return result;
  };

  const calcAverageRate = (rates: Rate[]) => {
    if (rates.length === 0) return null;
    const sum = rates.reduce((acc, cur) => acc + cur.value, 0);
    return sum / rates.length;
  };

  const averageRatesByCategory = [
    { label: RateLabels.smell, vale: calcAverageRate(filterRatesByCategory("smell")) },
    { label: RateLabels.thickness, vale: calcAverageRate(filterRatesByCategory("thickness")) },
    { label: RateLabels.touch, vale: calcAverageRate(filterRatesByCategory("touch")) },
    { label: RateLabels.design, vale: calcAverageRate(filterRatesByCategory("design")) },
    { label: RateLabels.evaluation, vale: calcAverageRate(filterRatesByCategory("evaluation")) },
  ];

  const sortByCategory = (rates: Rate[]) => {
    return rates.sort((a, b) => CategoryOrder.indexOf(a.category) - CategoryOrder.indexOf(b.category))
  };

  if (!product || !id) return <ImageLoading />;

  return (
    <>
      <StyledContainer>
        {isLogin && setupStatus !== "done" && (
          <Box mb={6}>
            <Alert severity="warning"><Link href={SETUP_ROUTE}>こちら</Link>から必須項目を入力してください。</Alert>
          </Box>
        )}

        <StyledProductContainer>
          <StyledProductImageContainer>
            <img
              src={product.imgUrl}
              alt={product.title}
              style={{ maxWidth: "100%", backgroundSize: "contain" }}
            />
          </StyledProductImageContainer>

          <Box>
            <StyledTitle onClick={() => openProductPage(product.url)}>{product.title}</StyledTitle>

            <StyleLabel>{product.brand?.label}</StyleLabel>

            {product.tags.length !== 0 && (
              <Box mt={1} fontSize={14} display="flex" flexWrap="wrap" gap={0.5}>
                {product.tags.map((tag) => (
                  <TagLabel key={tag.id}>{tag.label}</TagLabel>
                ))}
              </Box>
            )}

            <Box mt={3}>{product.evangelistReview}</Box>

            <Box mt={3}>
              {averageRatesByCategory.map((averateRate) => (
                <Box key={averateRate.label} display="flex" alignItems="center">
                  <Box fontWeight={700} fontSize={14} width={80}>{averateRate.label}</Box>
                  <Box width={30}>{averateRate.vale ? Math.round(averateRate.vale * 10) / 10 : "-"}</Box>
                  <Rating value={averateRate.vale} size="large" readOnly precision={0.1} />
                </Box>
              ))}
            </Box>

            <Box mt={3}>
              <StyledBuyButton variant="contained" onClick={() => openProductPage(product.url)}>購入する</StyledBuyButton>
            </Box>
          </Box>
        </StyledProductContainer>

        <Box mt={9}>
          <Box fontSize={21} fontWeight={700}>ユーザーレビュー</Box>
          <StyledReviewButtonContainer>
            <Box>この商品の感想を教えてください。</Box>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (!isLogin) {
                  alert("レビューはログイン後に行えます。");
                } else if (setupStatus !== "done") {
                  openSetupModal();
                } else {
                  openReviewModal();
                }
              }}
            >
              レビューする
            </Button>
          </StyledReviewButtonContainer>
          <Box mt={2}>{reviews.length}件のレビュー</Box>
          {reviews.map((review) => (
            <Box key={review.id}>
              <Box my={3} fontSize={14}>
                <StyledRatingContainer>
                  {sortByCategory(review.rates).map((rate: Rate) => (
                      <Box key={rate.id} display="flex" alignItems="center">
                        <StyledCategoryLabel>{RateLabels[rate.category]}</StyledCategoryLabel>
                        <Rating value={rate.value} size="small" readOnly precision={0.1} />
                      </Box>
                  ))}
                </StyledRatingContainer>
                <Box mt={1}>
                  {review.user ? (
                    <StyledNameContainer>
                      <Box>{review.user.nickname}: {UseCaseLabels[review.user.usecase]}</Box>
                      {currentUser?.uid === review.user?.id && (
                        <IconButton onClick={() => handleDelete(review)}>
                          <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                      )}
                    </StyledNameContainer>
                  ) : (
                    <Box>退会ユーザー</Box>
                  )}
                </Box>
                <Box mt={2}>{review.comment}</Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </StyledContainer>

      <Dialog
        open={isOpenSetupnModal}
        onClose={closeSetupModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>初期設定を完了してください</DialogTitle>
        <DialogContent>
          必須項目を入力するとレビューが行えるようになります。
        </DialogContent>
        <DialogActions>
        <Button onClick={closeSetupModal}>キャンセル</Button>
          <Button onClick={() => navigate(SETUP_ROUTE)} autoFocus>
            初期設定画面へ遷移する
          </Button>
        </DialogActions>
      </Dialog>

      <ReviewModal
        oncloseReviewModal={closeReviewModal}
        open={isOpenReviewModal}
        createReview={async (reviewObj : ReviewType) => {
          if (!currentUser) {
            alert("レビューはログイン後に行えます。");
            return;
          };

          const { smellRate, thicknessRate, touchRate, designRate, evaluationRate, review } = reviewObj;
          await createReview({ smellRate, thicknessRate, touchRate, designRate, evaluationRate, review }, product.id, currentUser.uid);
          fetchReviewsByProduct(id);
        }}
      />
    </>
  )
};

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 8),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  }
}));

const StyledProductImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent:"center",
  height: 400,

  [theme.breakpoints.down("sm")]: {
    height: 350,
  },
}));

const StyledProductContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 6fr",
  gap: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: 30,
  textDecoration: "underline",
  textDecorationColor: theme.palette.primary.main,
  textUnderlineOffset: 6,
  cursor: "pointer",
}));

const StyledBuyButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyleLabel = styled(Box)(({ theme }) => ({
  fontSize: 20,
  marginTop: theme.spacing(1),
}));

const StyledRatingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}));

const StyledNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledCategoryLabel = styled(Box)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(12),
  },
}));

const StyledReviewButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "initial",
  },
}));
