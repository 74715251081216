import {
  Container,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Chip,
  Box,
  useMediaQuery,
  useTheme,
  CardMedia,
} from "@mui/material";
import GuideIcon from "@mui/icons-material/MenuBook";
import { Article } from "../../types/frontend/article";
import { getAllArticles } from "../../api/article";
import { useEffect, useState } from "react";
import ImageIcon from '@mui/icons-material/Image';

export function ChoiceGuide() {
  const [articles, setArticles] = useState<Article[] | null>(null);

  useEffect(() => {
    getAllArticles().then(setArticles);
  }, []);

  return (
    <Container
      sx={{
        padding: "40px 0",
      }}
    >
      <Box textAlign="center" marginBottom={3}>
        <GuideIcon fontSize="large" />
        <Typography variant="h5" component="h1">
          コンドームの選び方ガイド
        </Typography>
        <Typography variant="body1" color="textSecondary">
          あなたにぴったりのコンドームを見つけるための全ての情報がここにあります。
        </Typography>
      </Box>
      {articles?.map((article) => (
        <ArticlePreview article={article} key={article.id}/>
      ))}
    </Container>
  );
}

function ArticlePreview({ article }: { article: Article }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ margin: isMobile ? 2 : 0 }}>
      <Card sx={{ marginBottom: 3 }}>
        <CardActionArea href={article.url} target="_blank">
        <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems="flex-start"
          >
            {article.imageUrl ? (
              <CardMedia
                component="img"
                image={article.imageUrl}
                alt={article.title}
                sx={{ width: isMobile ? "100%" : 120 }}
              />
            ) : (
              <ImageIcon
                fontSize="large"
                sx={{ width: isMobile ? "100%" : 120, height: isMobile ? "100%" : 120 }}
              />
            )}
            <CardContent
              sx={{ padding: "8px 16px", paddingBottom: "8px !important" }}
            >
              <Typography variant="h6" gutterBottom>
                {isMobile && article.title.length > 30
                  ? article.title.substring(0, 30) + "..."
                  : article.title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginBottom: 1 }}
              >
                {isMobile && article.summary.length > 60
                  ? article.summary.substring(0, 60) + "..."
                  : article.summary}
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {article.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))}
              </Box>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}
