import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0d4f33', // 暗めのグリーン
      contrastText: '#fff',
    },
    secondary: {
      main: '#F5F5DC', // 淡いベージュ
      contrastText: '#000',
    },
    background: {
      default: '#faf0e6', // オフホワイト
      paper: '#fff', // ホワイト
    },
    text: {
      primary: '#000',
      secondary: '#0d4f33',
    },
    success: {
      main: '#90ee90',
    },
    error: {
      main: '#d9534f',
    },
    warning: {
      main: '#ffd700', // ゴールド
    },
    info: {
      main: '#5bc0de',
    },
  },
  typography: {
    fontFamily: 'Noto Sans JP, sans-serif',
    h1: {
      fontFamily: 'M PLUS 1p, sans-serif',
    },
    h2: {
      fontFamily: 'M PLUS 1p, sans-serif',
    },
    // ...他の見出しスタイルも同様に設定
  }
});

export default theme;