import { MainVisual } from "./parts/MainVisual";
import { ServiceIntroduction } from "./parts/ServiceIntroduction";
import { ChoiceGuideIntroduction } from "./parts/ChoiceGuideIntroduction";
import { ContactToSns } from "./parts/ContactToSns";
import { DiagnosisIntroduction } from "./parts/DiagnosisIntroduction";

export default function Home() {
  return (
    <>
      {/* メインビジュアル */}
      <MainVisual />

      {/* サービスの簡単な紹介 */}
      <ServiceIntroduction />

      <ChoiceGuideIntroduction />

      <DiagnosisIntroduction />

      <ContactToSns />
    </>
  );
}