import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { checkUser, signUpWithEmail } from "../../../api/account";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HOME_ROUTE, LOGIN_ROUTE } from "../../../routes";
import { useNotificationContext } from "../../../providers/NotificationProvider";

export default function SignUpForm() {
  const { showNotification } = useNotificationContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");
    const nickname = data.get("nickname");
    const birthYear = data.get("birthYear");
    const usecase = data.get("usecase");

    const missingFields = [];
    if (!email) missingFields.push("メールアドレス");
    if (!password) missingFields.push("パスワード");
    if (!nickname) missingFields.push("ニックネーム");
    if (!birthYear) missingFields.push("誕生年");
    if (!usecase) missingFields.push("嗜好");

    if (missingFields.length > 0) {
      setErrorMessage(`${missingFields.join('と')}を入力してください。`);
      return;
    }
    setErrorMessage(null);

    const result = await signUpWithEmail(
      String(data.get("email")),
      String(data.get("password")),
      String(data.get("nickname")),
      Number(data.get("birthYear")),
      data.get("usecase") as "both" | "insert" | "inserted"
    );
    if (result === "success") {
      showNotification("アカウントを作成しました");
      navigate(HOME_ROUTE);
    } else {
      if(result === "illegalEmail") setErrorMessage("有効でないメールアドレスです。");
      if(result === "alreadyInUse") setErrorMessage("すでに使われているメールアドレスです。");
      if(result === "error") setErrorMessage("予期せぬエラーが発生しました");
      return;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    checkUser().then((result) => result && navigate(HOME_ROUTE));
  }, [navigate]);

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="メールアドレス"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="パスワード"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="nickname"
        label="ニックネーム"
        type="text"
        id="nickname"
        inputProps={{ maxLength: 10 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="birthYear"
        label="誕生年"
        type="number"
        id="birthYear"
        inputProps={{
          min: 1950,
          max: new Date().getFullYear(),
        }}
      />
      <FormControl fullWidth margin="normal" required>
        <InputLabel id="usecase-label">利用シーン</InputLabel>
        <Select
          labelId="usecase-label"
          id="usecase"
          name="usecase"
          defaultValue="insert"
        >
          <MenuItem value="insert">挿入することが多い</MenuItem>
          <MenuItem value="inserted">挿入されることが多い</MenuItem>
          <MenuItem value="both">どちらとも行う</MenuItem>
        </Select>
      </FormControl>

      {errorMessage && (
        <Typography component="h3" variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        新規登録
      </Button>

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Link to={LOGIN_ROUTE} >
            {"アカウントをお持ちの方"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}