import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { LinkButton } from "../../../components/LinkButton";
import { LightbulbOutlined } from "@mui/icons-material";
import { CHOICE_GUIDE_ROUTE } from "../../../routes";

export const ChoiceGuideIntroduction: React.FC = () => {
  return (
    <StyledSectionContainer>
      <StyledTitleContainer>
        <Typography variant="h4">
          選び方ガイド
        </Typography>
      </StyledTitleContainer>
      <Box>
        <StyledContentContainer>
          <Box>「コンドームを選べって言われてもわかんないよ！」そんなあなたに選び方のガイドを準備しました。</Box>
        </StyledContentContainer>
        <StyledButtonContainer>
          <LinkButton
            to={CHOICE_GUIDE_ROUTE}
            label={"選び方ガイド"}
            icon={<LightbulbOutlined />}
          />
        </StyledButtonContainer>
      </Box>
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  marginTop: theme.spacing(10),

  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  },
}));

const StyledTitleContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4),
}));

const StyledContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));