import { useState } from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProductList from "./part/ProductList";
import TagList from "./part/TagList";
import BrandList from "./part/BrandList";
import ArticleList from "./part/ArticleList";

const AdminView = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledContainer>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="カタログ" />
        <Tab label="タグ" />
        <Tab label="ブランド" />
        <Tab label="記事" />
      </Tabs>
      <Box pt={3}>
        {value === 0 && <ProductList />}
        {value === 1 && <TagList />}
        {value === 2 && <BrandList />}
        {value === 3 && <ArticleList />}
      </Box>
    </StyledContainer>
  )
};

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 8),
}));

export default AdminView;
