import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/Home/Home";
import Header from "./layouts/Header";
import Login from "./views/Login/Login";
import {
  CATALOG_ROUTE,
  CATALOG_DETAIL_ROUTE,
  CHOICE_GUIDE_ROUTE,
  HOME_ROUTE,
  ADMIN_ROUTE,
  LOGIN_ROUTE,
  MYPAGE_ROUTE,
  PASSWORD_RESET_ROUTE,
  SIGNUP_ROUTE,
  SETUP_ROUTE
} from "./routes";
import SignUp from "./views/SignUp/SignUp";
import PasswordReset from "./views/PasswordReset/PasswordReset";
import AdminView from "./views/Admin/AdminView";
import { Mypage } from "./views/Mypage/Mypage";
import { ChoiceGuide } from "./views/ChoiceGuide/ChoiceGuide";
import { CatalogView } from "./views/Catalog/CatalogView";
import { CatalogDetailView } from "./views/Catalog/CatalogDetailView";
import SetupFormView from "./views/Setup/SetupFormView";

function App() {
  return (
    <Router>
      <Header />

      <Routes>
        <Route path={SETUP_ROUTE} element={<SetupFormView />} />
        <Route path={HOME_ROUTE} element={<Home />} />
        <Route path={MYPAGE_ROUTE} element={<Mypage />} />
        <Route path={ADMIN_ROUTE} element={<AdminView />} />
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={SIGNUP_ROUTE} element={<SignUp />} />
        <Route path={PASSWORD_RESET_ROUTE} element={<PasswordReset />} />
        <Route path={CHOICE_GUIDE_ROUTE} element={<ChoiceGuide />} />
        <Route path={CATALOG_ROUTE} element={<CatalogView />} />
        <Route path={CATALOG_DETAIL_ROUTE} element={<CatalogDetailView />} />
      </Routes>
    </Router>
  );
}


export default App;