import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getAllProductTags } from "../../../api/productTag";
import { ProductTag } from "../../../types/database/productTag";
import { getAllProductBrands } from "../../../api/productBrand";
import { ProductBrand } from "../../../types/database/productBrand";
import { Product as ProductOnFront } from "../../../types/frontend/product";
import { Product } from "../../../types/database/product";
import { createProduct, updateProduct } from "../../../api/product";

type Props = {
  defaultValues: ProductOnFront | null;
  open: boolean;
  onClose: () => void;
  reload: () => void;
};

type ProductFormValues = Pick<
  Product,
  "title" | "evangelistReview" | "url" | "urlFrom" | "imgUrl" | "tagIds" | "brandId"
>;

const DefaultFormValues: ProductFormValues = {
  title: "",
  evangelistReview: "",
  url: "",
  urlFrom: "",
  imgUrl: "",
  tagIds: [],
  brandId: "",
};

const ProductFormDialog = ({
  defaultValues,
  open,
  onClose,
  reload,
}: Props) => {
  const [tags, setTags] = useState<ProductTag[]>([]);
  const [brands, setBrands] = useState<ProductBrand[]>([]);
  const [formValues, setFormValues] = useState<ProductFormValues>(DefaultFormValues);
  const isEdit = Boolean(defaultValues);

  useEffect(() => {
    Promise.all([getAllProductTags(), getAllProductBrands()])
      .then(([tags, brands]) => {
        setTags(tags);
        setBrands(brands);
      });
  }, []);

  useEffect(() => {
    if (!defaultValues) return;
    setFormValues({
      title: defaultValues.title || DefaultFormValues.title,
      evangelistReview: defaultValues.evangelistReview || DefaultFormValues.evangelistReview,
      url: defaultValues.url || DefaultFormValues.url,
      urlFrom: defaultValues.urlFrom || DefaultFormValues.urlFrom,
      imgUrl: defaultValues.imgUrl || DefaultFormValues.imgUrl,
      tagIds: defaultValues.tags.map((tag) => tag.id) || DefaultFormValues.tagIds,
      brandId: defaultValues.brand?.id || DefaultFormValues.brandId,
    });
  }, [defaultValues]);

  const handleClickSubmit = async () => {
    // URLチェック
    const isUrl = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/g.test(formValues.url);
    if (!isUrl) {
      alert("URLの形式が正しくありません");
      return;
    };

    if (!!defaultValues) {
      await updateProduct(defaultValues.id, formValues);
    } else {
      await createProduct(formValues);
    };

    reload();
    onClose();
    setFormValues(DefaultFormValues);
  };

  const handleClose = () => {
    onClose();
    setFormValues(DefaultFormValues);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>商品の{isEdit ? "編集" : "追加"}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box>画像URL</Box>
          <Input
            fullWidth
            value={formValues.imgUrl}
            onChange={({ target }) => setFormValues({ ...formValues, imgUrl: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>タイトル</Box>
          <Input
            fullWidth
            value={formValues.title}
            onChange={({ target }) => setFormValues({ ...formValues, title: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>エバンジェリストレビュー</Box>
          <Input
            multiline
            fullWidth
            rows={6}
            value={formValues.evangelistReview}
            onChange={({ target }) => setFormValues({ ...formValues, evangelistReview: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>URL</Box>
          <Input
            fullWidth
            value={formValues.url}
            onChange={({ target }) => setFormValues({ ...formValues, url: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>URL元</Box>
          <Input
            fullWidth
            value={formValues.urlFrom}
            onChange={({ target }) => setFormValues({ ...formValues, urlFrom: target.value })}
          />
        </Box>

        <Box mb={3}>
          <Box>タグ</Box>
          <Select
            multiple
            value={formValues.tagIds}
            onChange={({ target }) => {
              // mupltipleの場合、target.valueは配列
              setFormValues({ ...formValues, tagIds: target.value as string[] })
            }}
            input={<Input />}
            renderValue={(selectedIds) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selectedIds.map((tagId) => (
                  <Chip key={tagId} label={tags.find((tag) => tag.id === tagId)?.label} />
                ))}
              </Box>
            )}
          >
            {tags.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>{tag.label}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Box>ブランド</Box>
          <Select
            variant="standard"
            style={{ minWidth: 200 }}
            value={formValues.brandId}
            onChange={({ target }) => setFormValues({ ...formValues, brandId: target.value })}
          >
            {brands.map((brand) => (
              <MenuItem key={brand.id} value={brand.id}>{brand.label}</MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleClickSubmit}>{isEdit ? "変更" : "作成"}</Button>
      </DialogActions>
    </Dialog>
  )
};

export default ProductFormDialog;
