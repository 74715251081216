import { useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styled from "@mui/material/styles/styled";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { HOME_ROUTE } from "../../routes";
import { checkUser } from "../../api/account";
import { useLoginContext } from "../../providers/LoginContextProvider";
import XSignUp from "../../components/XSignUp";
import GoogleSignUp from "../../components/GoogleSignUp";
import ImageLoading from "../../components/ImageLoading";
import EmailLoginForm from "./parts/EmailLoginForm";

export default function Login() {
  const navigate = useNavigate();
  const { isLogin, setupStatus } = useLoginContext();

  useEffect(() => {
    checkUser().then((result) => result && navigate(HOME_ROUTE));
  }, [navigate]);

  if (isLogin && setupStatus === null) return <ImageLoading />;
  if (isLogin && setupStatus === "notDone") return <Navigate replace to="/setup"/>;
  if (isLogin && setupStatus === "done") return <Navigate replace to="/"/>;

  return (
    <StyledContainer>
      <CssBaseline />

      <StyledTitleContainer>
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        ログイン
        </Typography>
      </StyledTitleContainer>

      <StyledLoginContainer>
        <StyledSnsLoginContainer>
          <GoogleSignUp type="login" />
          <XSignUp type="login" />
        </StyledSnsLoginContainer>

        <EmailLoginForm/>
      </StyledLoginContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 750,
  margin: "0 auto",
  marginTop: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 2),
  }
}));

const StyledTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const StyledLoginContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  alignItems: "start",
  gap: theme.spacing(4),

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const StyledSnsLoginContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));
