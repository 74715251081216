import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import styled from "@mui/material/styles/styled";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { useNotificationContext } from "../../providers/NotificationProvider";
import {UserProfile } from "../../types/database/userProfile";
import { updateUser } from "../../api/user";
import ErrorMessageLabel from "../../components/ErrorMessageLabel";

const DefaultValues ={
  nickname: "",
  birthYear: "",
  usecase: "",
};

type FormValues = Pick<UserProfile, "nickname" | "birthYear" | "usecase">;

const SetupFormView = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();
  const { userProfile, reloadUserProfile } = useLoginContext();
  const {
    control,
    handleSubmit,
    formState
  } = useForm<typeof DefaultValues, any, FormValues>({ defaultValues: DefaultValues });
  const { errors } = formState;

  const onSubmit = async (data: FormValues) => {
    if (!userProfile) return;
    await updateUser(userProfile.id, data);

    reloadUserProfile();
    showNotification("必須項目を入力しました");
    navigate(-1);
  };

  useEffect(() => {
    reloadUserProfile();
  }, [reloadUserProfile]);

  return (
    <StyledContainer maxWidth="xs">
      <CssBaseline />

      <StyledTitle>必須入力項目</StyledTitle>

      <StyledFormContainer>
        <StyledFieldContainer>
          <Controller
            name="nickname"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField {...field} fullWidth label="ニックネーム" type="text" />
            )}
          />
          {errors.nickname && <ErrorMessageLabel>ニックネームを入力してください</ErrorMessageLabel>}
        </StyledFieldContainer>

        <StyledFieldContainer>
          <Controller
            name="birthYear"
            control={control}
            rules={{ required: true, min: 1920, max: new Date().getFullYear() }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="誕生年"
                type="number"
                onChange={(event) => field.onChange(Number(event.target.value))}
              />
            )}
          />
          {errors.birthYear?.type === "required"
            ? <ErrorMessageLabel>誕生年を入力してください</ErrorMessageLabel>
            : (errors.birthYear?.type === "min" || errors.birthYear?.type === "max")
            ? <ErrorMessageLabel>誕生年を正しく入力してください</ErrorMessageLabel>
            : null
          }
        </StyledFieldContainer>

        <StyledFieldContainer>
          <FormControl fullWidth>
            <InputLabel id="usecase">利用シーン</InputLabel>
            <Controller
              name="usecase"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="usecase"
                  id="demo-simple-select"
                  label="利用シーン"
                >
                  <MenuItem value="insert">挿入することが多い</MenuItem>
                  <MenuItem value="inserted">挿入されることが多い</MenuItem>
                  <MenuItem value="both">どちらとも行う</MenuItem>
                </Select>
              )}
            />
            {errors.usecase && <ErrorMessageLabel>利用シーンを入力してください</ErrorMessageLabel>}
          </FormControl>
        </StyledFieldContainer>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          登録
        </Button>
      </StyledFormContainer>
    </StyledContainer>
  )
};

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 2),
  }
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const StyledFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: theme.spacing(3),
}));

const StyledFieldContainer = styled(Box)({
  width: "100%",
});

export default SetupFormView;
