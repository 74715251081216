import "./api/firebaseInit";
import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { LoginContextProvider } from "./providers/LoginContextProvider";
import { NotificationProvider } from "./providers/NotificationProvider"
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Noto Sans JP:400,700", "M PLUS Rounded 1c:400,700"]
  }
});


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LoginContextProvider>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </LoginContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();