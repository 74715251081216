import {
  createContext as reactCreateContext,
  useContext as reactUseContext
} from "react";

export function createContext<T>() {
  const context = reactCreateContext<T | undefined>(undefined);
  const useContext = () => {
    const values = reactUseContext(context);
    if (values === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }

    return values;
  }

  return [useContext, context] as const;
}
