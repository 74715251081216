import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import CatalogIcon from "@mui/icons-material/MenuBook"; // カタログアイコン
import { LightbulbOutlined } from "@mui/icons-material";
import { CATALOG_ROUTE, CHOICE_GUIDE_ROUTE } from "../../../routes";

export const ServiceIntroduction: React.FC = () => {
  return (
    <StyledSectionContainer>
      <StyledCatchphrase>あなたにぴったりのコンドームを、楽しく見つけよう</StyledCatchphrase>
      <Typography variant="subtitle1" sx={{ marginBottom: 5, textAlign: "center" }}>
        コンドームって本当は色々あるんです。このサービスを通じて、自分に合ったものを見つけると同時に選ぶ楽しみを知り、より安全で充実した性生活を謳歌しましょう。
      </Typography>
      <StyledServiceFeatureContainer>
        {/* <ServiceFeature
          Icon={<DiagnosisIcon style={{ fontSize: '4rem' }}/>}
          title={"最適コンドーム診断"}
          description={
            "簡単な質問に答えるだけで、あなたの好みに合ったコンドームを診断します。"
          }
        /> */}
        <ServiceFeature
          path={CATALOG_ROUTE}
          Icon={<CatalogIcon style={{ fontSize: '4rem' }}/>}
          title={"コンドームカタログ"}
          description={
            "使用者のレビューや中立の立場から見た特徴を見ながらコンドームを選んだり探したりできます。"
          }
        />
        <ServiceFeature
        path={CHOICE_GUIDE_ROUTE}
          Icon={<LightbulbOutlined style={{ fontSize: '4rem' }}/>}
          title={"コンドームの選び方"}
          description={
            "コンドームの選び方、管理人が記事形式で紹介しています。迷ったらまずはここから！"
          }
        />
      </StyledServiceFeatureContainer>
    </StyledSectionContainer>
  );
};

type ServiceFeatureProps = {
  path: string;
  Icon: React.ReactNode;
  title: string;
  description: string;
};

const ServiceFeature = ({
  path,
  Icon,
  title,
  description,
}: ServiceFeatureProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      onClick={() => navigate(path)}
      sx={{
        textAlign: "center",
        flexDirection: isMobile ? 'row' : 'column',
        margin: "10px",
        maxWidth: '300px',
        padding: '20px',
        borderRadius: '10px', // 角を丸く
        transition: '0.3s', // トランジション効果
        cursor: 'pointer', // カーソルを指のマークに
        '&:hover': { // ホバー時の効果
          background: 'rgba(0, 0, 0, 0.1)', // 背景色
          transform: 'scale(1.05)', // サイズを大きく
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // 影をつける
        }
      }}
    >
      <Box sx={{ fontSize: "4rem" }}>{Icon}</Box>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>{title}</Typography> {/* タイトルと説明の隙間を開ける */}
      <Typography variant="body1" sx={{ color: 'text.secondary' }}>{description}</Typography>
    </Box>
  );
};

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  marginTop: theme.spacing(10),

  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledCatchphrase = styled(Box)(({ theme }) => ({
  fontSize: 40,
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  textAlign: "center",
  marginBottom: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
}));

const StyledServiceFeatureContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));