import { useState, useEffect } from "react";
import styled from "@mui/material/styles/styled";
import NotFoundIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { SETUP_ROUTE } from "../../routes";
import { useBooleanState } from "../../lib/hooks";
import { getAllProducts } from "../../api/product";
import { getAllProductTags } from "../../api/productTag";
import { getAllProductBrands } from "../../api/productBrand";
import { ProductTag } from "../../types/database/productTag";
import { ProductBrand } from "../../types/database/productBrand";
import { Product } from "../../types/frontend/product";
import TagLabel from "../../components/TagLabel";

type ConditionType = "brand" | "tag" | "evaluationRate";

type Condition = {
  brandIds: string[];
  tagIds: string[];
  evaluationRate: number | null;
};

const RateLabels = [1,2,3,4];

const defaultCondition: Condition = {
  brandIds: [],
  tagIds: [],
  evaluationRate: null,
};

export const CatalogView = () => {
  const { isLogin, setupStatus } = useLoginContext();
  const [isOpenBrandList, , , toggleBrandList] = useBooleanState(true);
  const [isOpenTagList, , , toggleTagList] = useBooleanState(true);
  const [isOpenEvaluationRateList, , , toggleEvaluationRateList] = useBooleanState(true);
  const [brands, setBrands] = useState<ProductBrand[]>([]);
  const [tags, setTags] = useState<ProductTag[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [filterCondition, setFilterCondition] = useState<Condition>(defaultCondition);

  useEffect(() => {
    Promise.all([getAllProducts(), getAllProductBrands(), getAllProductTags()])
      .then(([products, brands, tags]) => {
        setAllProducts(products);
        setFilteredProducts(products);
        setBrands(brands);
        setTags(tags);
      });
  }, []);

  const changeCondition = (type: ConditionType, value: number | string) => {
    const newCondition = {...filterCondition};
    // ここの型チェックなんとかしたい
    if (type === "brand" && typeof value === "string") {
      // ここらへんのメソッド一緒にできそう
      const index = newCondition.brandIds.indexOf(value);
      if (index === -1) {
        newCondition.brandIds.push(value);
      } else {
        newCondition.brandIds.splice(index, 1);
      };
    };

    if (type === "tag" && typeof value === "string") {
      const index = newCondition.tagIds.indexOf(value);
      if (index === -1) {
        newCondition.tagIds.push(value);
      } else {
        newCondition.tagIds.splice(index, 1);
      };
    };

    if (type === "evaluationRate" && typeof value === "number") {
      newCondition.evaluationRate = newCondition.evaluationRate === value ? null : value;
    };

    setFilterCondition(newCondition);
    return newCondition;
  };

  const filterProducts = (condition: Condition) => {
    const noFilterCondition = condition.brandIds.length === 0 &&
      condition.tagIds.length === 0 &&
      condition.evaluationRate === null;

    if (noFilterCondition) {
      setFilteredProducts(allProducts);
      return;
    };

    const newProducts = allProducts.filter((product) => {
      const brandId = product.brand?.id;
      const tagIds = product.tags.map((tag) => tag.id);

      const isBrandMatched = condition.brandIds.length === 0 || condition.brandIds.includes(brandId);
      const isTagMatched = condition.tagIds.length === 0 || condition.tagIds.some((tagId) => tagIds.includes(tagId));
      const isEvaluationRateMatched = condition.evaluationRate === null || Number(product.evaluationAverageRate) >= condition.evaluationRate;

      return isBrandMatched && isTagMatched && isEvaluationRateMatched;
    });

    setFilteredProducts(newProducts);
  };

  return (
    <StyledContainer>
      {isLogin && setupStatus !== "done" && (
        <Box mb={6}>
          <Alert severity="warning"><Link href={SETUP_ROUTE}>こちら</Link>から必須項目を入力してください。</Alert>
        </Box>
      )}

      <StyledContentContainer>
        <List subheader={<ListSubheader style={{ fontSize: 18 }}>絞り込み条件</ListSubheader>}>
          <FilterListItem
            label="ブランド"
            openList={toggleBrandList}
            isOpenList={isOpenBrandList}
            data={brands}
            filterItemIds={filterCondition.brandIds}
            filterProducts={(selectedBrandId) => {
              const newFilterCondition = changeCondition("brand", selectedBrandId);
              filterProducts(newFilterCondition);
            }}
          />
  
          <FilterListItem
            label="タグ"
            openList={toggleTagList}
            isOpenList={isOpenTagList}
            data={tags}
            filterItemIds={filterCondition.tagIds}
            filterProducts={(selectedTagId) => {
              const newFilterCondition = changeCondition("tag", selectedTagId);
              filterProducts(newFilterCondition);
            }}
          />
  
          <ListItemButton onClick={toggleEvaluationRateList}>
            <ListItemText primary="総合評価" />
            {isOpenEvaluationRateList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenEvaluationRateList} timeout="auto">
            <List disablePadding>
              {RateLabels.map((value) => (
                <ListItem key={value} disablePadding>
                  <StyleListItemButton
                    dense
                    selected={filterCondition.evaluationRate === value}
                    onClick={() => {
                      const newFilterCondition = changeCondition("evaluationRate", value);
                      filterProducts(newFilterCondition);
                    }}
                  >
                    <Rating value={value} readOnly />
                    <Box ml={0.5} fontSize={14}>以上</Box>
                  </StyleListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
  
        {filteredProducts.length === 0 ? (
          <StyledNotFoundContainer>
            <NotFoundIcon />
            <Box>該当する商品が見つかりませんでした</Box>
          </StyledNotFoundContainer>
        ) : (
          <StyledProductCardContainer>
            {filteredProducts.map((product) => (
              <Box key={product.id}>
                <Card>
                  <CardActionArea href={`/catalog/${product.id}`} target="_blank" rel="noopener noreferrer">
                    <CardMedia sx={{ height: 150, backgroundSize: "contain" }} image={product.imgUrl} />
                    <CardContent>
                      <Box fontSize={18}>{product.title}</Box>
                      <Box mt={0.5} display="flex" alignItems="center" gap={0.5}>
                        <Box fontSize={14}>{product.evaluationAverageRate ? Math.round(product.evaluationAverageRate * 10) / 10 : "-"}</Box>
                        <Rating value={product.evaluationAverageRate} size="small" precision={0.1} readOnly />
                      </Box>
                      {product.tags.length > 0 && (
                        <Box mt={1} fontSize={14} display="flex" flexWrap="wrap" gap={0.5}>
                          {product.tags.map((tag) => (
                            <TagLabel key={tag.id}>{tag.label}</TagLabel>
                          ))}
                        </Box>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            ))}
          </StyledProductCardContainer>
        )}
      </StyledContentContainer>
    </StyledContainer>
  );
};

type FilterListItemProps = {
  openList: () => void;
  isOpenList: boolean;
  label: string;
  data: ProductBrand[] | ProductTag[];
  filterProducts: (id: string) => void;
  filterItemIds: string[];
};

const FilterListItem = ({
  openList,
  isOpenList,
  label,
  data,
  filterProducts,
  filterItemIds,
}: FilterListItemProps) => {
  return (
    <>
      <ListItemButton onClick={openList}>
        <ListItemText primary={label} />
        {isOpenList ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpenList} timeout="auto">
        <List disablePadding>
          {data.map((item) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton onClick={() => filterProducts(item.id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filterItemIds.indexOf(item.id) !== -1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
};

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 8),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}));

const StyledContentContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 9fr",
  gap: theme.spacing(1),

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const StyledNotFoundContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(8, 0),
}));

const StyledProductCardContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const StyleListItemButton = styled(ListItemButton)<{ selected: boolean }>(({ selected }) => ({
  fontWeight: selected ? 700 : "normal"
}));
